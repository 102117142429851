/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000000;
  --card-background-color: #707070;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background-color:var(--main-background-color);
  /*background: url("img/background.png");
  background-size: cover;*/

  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

h3{
  text-align: left;
  font-family: Georgia, 'Times New Roman', Times, serif;
}



